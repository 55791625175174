import { Controller } from '@hotwired/stimulus'
import _ from 'lodash'

// The main provider of Google Tag Manager events. `window.dataLayer` should be initialized already on the page.
// Then there are to ways to provide an event:
//
//   <div data-controller="klaviyo-events" data-klaviyo-events-event-value="event_name"
//        data-klaviyo-events-payload-value="<%= some_data.to_json %>">
//
// In this case the event will be fired on controller being connected to the element. Or:
//
//   <a data-controller="klaviyo-events" data-klaviyo-events-event-value="event_name"
//      data-klaviyo-events-payload-param="<%= some_data.to_json %>" data-action="klaviyo-events#track"></a>
//
// In this case the event will be pushed on the link click or any other JS event.
//
export default class extends Controller {
  static values = {
    event: String,
    payload: Object
  }

  initialize () {
    // eslint-disable-next-line
    !(function () { if (!window.klaviyo) { window._klOnsite = window._klOnsite || []; try { window.klaviyo = new Proxy({}, { get: function (n, i) { return i === 'push' ? function () { let n; (n = window._klOnsite).push.apply(n, arguments) } : function () { for (var n = arguments.length, o = new Array(n), w = 0; w < n; w++)o[w] = arguments[w]; const t = typeof o[o.length - 1] === 'function' ? o.pop() : void 0; const e = new Promise(function (n) { window._klOnsite.push([i].concat(o, [function (i) { t && t(i), n(i) }])) }); return e } } }) } catch (n) { window.klaviyo = window.klaviyo || [], window.klaviyo.push = function () { let n; (n = window._klOnsite).push.apply(n, arguments) } } } }())
  }

  async connect () {
    if (this[this.eventValue]) {
      await this[this.eventValue]({ params: { [`${this.eventValue}Payload`]: this.payloadValue } })
    } else if (!_.isEmpty(this.payloadValue)) {
      await this.track({ params: { payload: this.payloadValue } })
    }
  }

  async track (e) {
    if (!_.isEmpty(this.eventValue) && !_.isEmpty(e.params.payload)) {
      await window.klaviyo.push(['track', this.eventValue, e.params.payload])
    }
  }

  async trackActivity (_e) {
    if (window._learnq && !_.isEmpty(window.turboReferrer)) {
      window._learnq.push((o) => {
        o.has_tracked_activity = false
        o.context.getReferrer = () => window.turboReferrer
      })
      window._learnq.push(['trackActivity'])
    }
  }

  async trackViewedItem (e) {
    if (!_.isEmpty(e.params.trackViewedItemPayload)) {
      await window.klaviyo.push(['trackViewedItem', e.params.trackViewedItemPayload])
    }
  }

  async identify (e) {
    if (!_.isEmpty(e.detail.email)) {
      await window.klaviyo.identify({ email: _.trim(_.toLower(e.detail.email)) })
    }
  }
}
