import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    fieldId: String
  }

  scrollToField () {
    const element = document.getElementById(this.fieldIdValue)

    element.scrollIntoView({ block: 'center' })
    element.classList.add('field-required')
    const removeAnimation = function (_event) {
      element.classList.remove('field-required')
      removeEventListener('animationend', removeAnimation, false)
    }
    element.focus()
    element.parentNode.addEventListener('animationend', removeAnimation, false)
  }
}
