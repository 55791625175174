import { Controller } from '@hotwired/stimulus'

const calculateTime = (secs) => {
  const minutes = Math.floor(secs / 60)
  const seconds = Math.floor(secs % 60)
  const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
  return `${minutes}:${returnedSeconds}`
}

export default class extends Controller {
  static targets = ['duration', 'audio', 'index', 'play', 'spinner', 'title']

  static values = {
    id: String,
    play: Boolean
  }

  connect () {
    this.audioTarget.onloadedmetadata = () => {
      this.durationTarget.innerHTML = calculateTime(this.audioTarget.duration)
    }
    this.audioTarget.ondurationchange = () => {
      if (this.hasSpinnerTarget) {
        this.spinnerTarget.hidden = false
      }
    }
    this.audioTarget.oncanplaythrough = () => {
      if (this.hasSpinnerTarget) {
        this.spinnerTarget.hidden = true
      }
    }
  }

  play (event) {
    this.playValue = !this.playValue
    this.stopOther(event.currentTarget)
  }

  playValueChanged () {
    if (this.playValue) {
      this.audioTarget.play()
      this.element.classList.add('bg-grey-light-plus')
      this.playTarget.classList.add('flex', 'bg-pause')
      this.playTarget.classList.remove('hidden', 'bg-play')
      this.indexTarget.classList.add('hidden')
      this.indexTarget.classList.remove('flex')
      this.titleTarget.classList.add('text-orange-main')
      this.titleTarget.classList.remove('text-grey-main')
      this.durationTarget.classList.add('text-orange-main')
      this.durationTarget.classList.remove('text-grey-main')
    } else {
      this.audioTarget.pause()
      this.element.classList.remove('bg-grey-light-plus')
      this.playTarget.classList.add('hidden', 'bg-play')
      this.playTarget.classList.remove('flex', 'bg-pause')
      this.indexTarget.classList.add('flex')
      this.indexTarget.classList.remove('hidden')
      this.titleTarget.classList.add('text-grey-main')
      this.titleTarget.classList.remove('text-orange-main')
      this.durationTarget.classList.add('text-grey-main')
      this.durationTarget.classList.remove('text-orange-main')
    }
  }

  stopOther (_excludedElement) {
    const elements = document.querySelectorAll('.audio-player')

    elements.forEach((el) => {
      if (this.element !== el) {
        el.dataset.productPlayerPlayValue = false
      }
    })
  }
}
