import { Controller } from '@hotwired/stimulus'
import _ from 'lodash'

export default class extends Controller {
  static values = {
    errorKey: String
  }

  static targets = [
    'wrapper',
    'input',
    'error'
  ]

  static classes = [
    'wrapperError',
    'inputError',
    'error'
  ]

  clearError (_e) {
    this.toggleError()
  }

  pickError (errors) {
    this.toggleError(errors[this.errorKeyValue])
  }

  toggleError (error) {
    if (_.isEmpty(error)) {
      if (this.hasWrapperTarget) {
        this.wrapperTarget.classList.remove(...this.wrapperErrorClasses)
      }
      if (this.hasInputTarget) {
        this.inputTarget.classList.remove(...this.inputErrorClasses)
      }
      if (this.hasErrorTarget) {
        this.errorTarget.classList.remove(...this.errorClasses)
        this.errorTarget.innerHTML = ''
      }
    } else {
      if (this.hasWrapperTarget) {
        this.wrapperTarget.classList.add(...this.wrapperErrorClasses)
      }
      if (this.hasInputTarget) {
        this.inputTarget.classList.add(...this.inputErrorClasses)
      }
      if (this.hasErrorTarget) {
        this.errorTarget.classList.add(...this.errorClasses)
        this.errorTarget.innerHTML = error
      }
    }
  }
}
