import { Controller } from '@hotwired/stimulus'
import { loadScript } from '@paypal/paypal-js'
import * as Sentry from '@sentry/browser'
import { getMeta, showErrorBox } from '../utils/dom'

const BUTTON_STYLE = {
  tagline: false,
  layout: 'vertical',
  color: 'white',
  label: 'paypal',
  borderRadius: 9999,
  disableMaxHeight: true,
  disableMaxWidth: true
}
const CARD_FIELDS_STYLE = {
  body: {
    padding: 0
  },
  input: {
    'font-family': '"Averta", "Arial", serif;',
    'font-style': 'normal',
    'font-weight': 'normal',
    'font-size': '16px',
    'line-height': '20px',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    appearance: 'none',
    background: '#fff',
    border: '1px solid #e2ddd9',
    'box-shadow': 'none',
    'border-radius': '8px',
    color: '#4a4847',
    height: '56px',
    outline: 'none'
  },
  ':focus': {
    border: '2px solid #ffb81c',
    'box-shadow': 'none'
  },
  '.invalid': {
    border: '2px solid #ff7500',
    'box-shadow': 'none'
  },
  'input.card-field-number.display-icon': {
    'padding-left': 'calc(1.2rem + 50px) !important'
  }
}

export default class extends Controller {
  static targets = [
    'paypalButton',
    'cardButton',
    'сardNumber',
    'cardExpiry',
    'cardCvv',
    'paymentMethod'
  ]

  static values = {
    clientId: String,
    currency: String,
    cardParams: Object
  }

  static confirmUrl
  static cardField
  static cvvField
  static expiryField
  static numberField

  initialize () {
    this.initPaypalButton = this.initPaypalButton.bind(this)
    this.initCardFields = this.initCardFields.bind(this)
    this.createPaypalOrder = this.createPaypalOrder.bind(this)
    this.onPaypalApprove = this.onPaypalApprove.bind(this)
    this.submitCard = this.submitCard.bind(this)
  }

  async connect () {
    const paypal = await loadScript({
      clientId: this.clientIdValue,
      currency: this.currencyValue.toUpperCase(),
      intent: 'capture',
      components: 'buttons,card-fields',
      disableFunding: 'credit'
    })

    if (this.hasPaypalButtonTarget) {
      this.initPaypalButton(paypal, { fundingSource: paypal.FUNDING.PAYPAL, container: this.paypalButtonTarget })
    }

    if (this.hasCardExpiryTarget && this.hasCardCvvTarget) {
      this.initCardFields(paypal, {
        сardNumber: this.сardNumberTarget,
        cardExpiry: this.cardExpiryTarget,
        cardCvv: this.cardCvvTarget
      })
    }
  }

  initPaypalButton (paypal, { fundingSource, container }) {
    const button = paypal.Buttons({
      fundingSource,
      style: BUTTON_STYLE,
      createOrder: this.createPaypalOrder,
      onApprove: this.onPaypalApprove,
      onError: this.onPaypalError
    })

    if (button.isEligible()) {
      button.render(container)
    } else {
      container.hidden = true
    }
  }

  initCardFields (paypal, { сardNumber, cardExpiry, cardCvv }) {
    this.cardField = paypal.CardFields({
      style: CARD_FIELDS_STYLE,
      inputEvents: {
        onInputSubmitRequest: async (data) => {
          await this.submitCard(data)
        }
      },
      createOrder: this.createPaypalOrder,
      onApprove: this.onPaypalApprove,
      onError: this.onPaypalError
    })

    this.cvvField = this.cardField.CVVField()
    this.cvvField.render(cardCvv)

    this.expiryField = this.cardField.ExpiryField({
      inputEvents: {
        onChange: (data) => {
          if (data.fields.cardExpiryField.isValid) {
            this.cvvField.focus()
          }
        }
      }
    })
    this.expiryField.render(cardExpiry)

    this.numberField = this.cardField.NumberField({
      inputEvents: {
        onChange: (data) => {
          if (data.fields.cardNumberField.isValid) {
            this.expiryField.focus()
          }
        }
      }
    })
    this.numberField.render(сardNumber)
  }

  async pay (event) {
    event.preventDefault()

    if (!this.cardField) { return }

    const cardFieldState = await this.cardField.getState()
    await this.submitCard(cardFieldState)
  }

  async submitCard (cardFieldState) {
    if (cardFieldState.isFormValid) {
      document.querySelector('#loader').classList.remove('hidden')
      await this.cardField.submit()
      document.querySelector('#loader').classList.add('hidden')
    } else {
      if (!cardFieldState.fields.cardNumberField.isValid) {
        this.numberField?.focus()
      } else if (!cardFieldState.fields.cardExpiryField.isValid) {
        this.expiryField?.focus()
      } else if (!cardFieldState.fields.cardCvvField.isValid) {
        this.cvvField?.focus()
      }
    }
  }

  async createPaypalOrder ({ paymentSource }) {
    this.paymentMethodTarget.value = paymentSource === 'paypal' ? 'paypal' : `paypal-${paymentSource}`

    const { form } = this.paymentMethodTarget
    const formData = new FormData(form)
    const response = await fetch(form.action, {
      method: form.method,
      body: formData
    })
    const responseData = await response.json()
    this.confirmUrl = responseData.confirm_url

    return responseData.token
  }

  async onPaypalApprove (data) {
    const { liabilityShift, orderID } = data

    if (liabilityShift === 'NO' || liabilityShift === 'UNKNOWN') {
      throw new Error('3DS check failed')
    } else {
      const csrfToken = getMeta('csrf-token')
      const response = await fetch(this.confirmUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        },
        body: JSON.stringify({ paypal_order_id: orderID })
      })

      const responseData = await response.json()
      if (response.ok) {
        window.location = responseData.redirect_url
      } else {
        throw new Error(JSON.stringify(responseData.message))
      }
    }
  }

  onPaypalError (error) {
    document.querySelector('#loader').classList.add('hidden')

    const jsonStart = error.message.indexOf('{')
    const jsonEnd = error.message.lastIndexOf('}')
    const maybeJson = error.message.substring(jsonStart, jsonEnd + 1)

    let message
    try {
      const errorData = JSON.parse(maybeJson)
      message = `${errorData.details[0].issue}: ${errorData.details[0].description}`
    } catch (e) {
      Sentry.captureException(error)
      Sentry.captureException(e, { extra: { paypalError: error.message } })
      message = 'Something went wrong, please try again or use another payment method'
    }

    showErrorBox('paypal-message-error', 'PayPal error', message)
  }
}
